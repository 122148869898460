 export const FAILED_LOGIN = "FAILED_LOGIN"
 export const USER_LOGIN = "USER_LOGIN"
 export const START_LOGIN = "START_LOGIN"
 export const GET_EMPLOYEES = "GET_EMPLOYEES"
 export const START_CAPTCHA = "START_CAPTCHA"
 export const CAPTCHA = "CAPTCHA"
 export const FAILED_CAPTCHA = "FAILED_CAPTCHA"
 export const START_GET_EMPLOYEES = "START_GET_EMPLOYEES"
 export const FAILED_GET_EMPLOYEES = "FAILED_GET_EMPLOYEES"
 export const START_GET_CONTACTS_WRIRING = "START_GET_CONTACTS_WRIRING"
 export const GET_CONTACTS_WRIRING = "GET_CONTACTS_WRIRING"
 export const FAILED_GET_CONTACTS_WRIRING = "FAILED_GET_CONTACTS_WRIRING"
 export const START_CREATE_CONTACTS_WRIRING = "START_CREATE_CONTACTS_WRIRING"
 export const CREATE_CONTACTS_WRIRING = "CREATE_CONTACTS_WRIRING"
 export const FAILED_CREATE_CONTACTS_WRIRING = "FAILED_CREATE_CONTACTS_WRIRING"
 export const START_CREATE_CATEGORY = "START_CREATE_CATEGORY"
 export const CREATE_CATEGORY = "CREATE_CATEGORY"
 export const FAILED_CREATE_CATEGORY = "FAILED_CREATE_CATEGORY"
 export const START_GET_CATEGORIES = "START_GET_CATEGORIES"
 export const GET_CATEGORIES = "GET_CATEGORIES"
 export const FAILED_GET_CATEGORIES = "FAILED_GET_CATEGORIES"
 export const START_GET_CATEGORIES_ACCOUNT = "START_GET_CATEGORIES_ACCOUNT"
 export const GET_CATEGORIES_ACCOUNT = "GET_CATEGORIES_ACCOUNT"
 export const FAILED_GET_CATEGORIES_ACCOUNT = "FAILED_GET_CATEGORIES_ACCOUNT"
 export const START_CREATE_CATEGORY_ACCOUNT = "START_CREATE_CATEGORY_ACCOUNT"
 export const CREATE_CATEGORY_ACCOUNT = "CREATE_CATEGORY_ACCOUNT"
 export const FAILED_CREATE_CATEGORY_ACCOUNT = "FAILED_CREATE_CATEGORY_ACCOUNT"
 export const START_GET_SUB_CATEGORIES_ACCOUNT = "START_GET_SUB_CATEGORIES_ACCOUNT"
 export const GET_SUB_CATEGORIES_ACCOUNT = "GET_SUB_CATEGORIES_ACCOUNT"
 export const FAILED_GET_SUB_CATEGORIES_ACCOUNT = "FAILED_GET_SUB_CATEGORIES_ACCOUNT"
 export const START_EDIT_CATEGORY_ACCOUNT = "START_EDIT_CATEGORY_ACCOUNT"
 export const EDIT_CATEGORY_ACCOUNT = "EDIT_CATEGORY_ACCOUNT"
 export const FAILED_EDIT_CATEGORY_ACCOUNT = "FAILED_EDIT_CATEGORY_ACCOUNT"
 export const START_DELETE_CATEGORY_ACCOUNT = "START_DELETE_CATEGORY_ACCOUNT"
 export const DELETE_CATEGORY_ACCOUNT = "DELETE_CATEGORY_ACCOUNT"
 export const FAILED_DELETE_CATEGORY_ACCOUNT = "FAILED_DELETE_CATEGORY_ACCOUNT"
 export const START_GET_ACCOUNTS = "START_GET_ACCOUNTS"
 export const GET_ACCOUNTS = "GET_ACCOUNTS"
 export const FAILED_GET_ACCOUNTS = "FAILED_GET_ACCOUNTS"
 export const START_CREATE_ACCOUNT_FILE = "START_CREATE_ACCOUNT_FILE"
 export const CREATE_ACCOUNT_FILE = "CREATE_ACCOUNT_FILE"
 export const FAILED_CREATE_ACCOUNT_FILE = "FAILED_CREATE_ACCOUNT_FILE"
 export const START_GET_LOCATION = "START_GET_LOCATION"
 export const GET_LOCATION = "GET_LOCATION"
 export const FAILED_GET_LOCATION = "FAILED_GET_LOCATION"
 export const START_CREATE_LOCATION = "START_CREATE_LOCATION"
 export const CREATE_LOCATION = "CREATE_LOCATION"
 export const FAILED_CREATE_LOCATION = "FAILED_CREATE_LOCATION"
 export const START_CREATE_EMPLOYEE = "START_CREATE_EMPLOYEE"
 export const CREATE_EMPLOYEE = "CREATE_EMPLOYEE"
 export const FAILED_CREATE_EMPLOYEE = "FAILED_CREATE_EMPLOYEE"
 export const START_GET_DEPARTMENT = "START_GET_DEPARTMENT"
 export const GET_DEPARTMENT = "GET_DEPARTMENT"
 export const FAILED_GET_DEPARTMENT = "FAILED_GET_DEPARTMENT"
 export const CREATE_DEPARTMENT = "CREATE_DEPARTMENT"
 export const START_EDIT_DEPARTMENT = "START_EDIT_DEPARTMENT"
 export const EDIT_DEPARTMENT = "EDIT_DEPARTMENT"
 export const FAILED_EDIT_DEPARTMENT = "FAILED_EDIT_DEPARTMENT"
 export const START_CREATE_DEPARTMENT = "START_CREATE_DEPARTMENT"
 export const FAILED_CREATE_DEPARTMENT = "FAILED_CREATE_DEPARTMENT"
 export const START_DELETE_DEPARTMENT = "START_DELETE_DEPARTMENT"
 export const DELETE_DEPARTMENT = "DELETE_DEPARTMENT"
 export const FAILED_DELETE_DEPARTMENT = "FAILED_DELETE_DEPARTMENT"
 export const START_DELETE_EMPLOYEE = "START_DELETE_EMPLOYEE"
 export const DELETE_EMPLOYEE = "DELETE_EMPLOYEE"
 export const FAILED_DELETE_EMPLOYEE = "FAILED_DELETE_EMPLOYEE"
 export const START_EDIT_EMPLOTEE = "START_EDIT_EMPLOTEE"
 export const EDIT_EMPLOTEE = "EDIT_EMPLOTEE"
 export const FAILED_EDIT_EMPLOTEE = "FAILED_EDIT_EMPLOTEE"
 export const START_EDIT_CATEGORY = "START_EDIT_CATEGORY"
 export const EDIT_CATEGORY = "EDIT_CATEGORY"
 export const FAILED_EDIT_CATEGORY = "FAILED_EDIT_CATEGORY"
 export const START_DELETE_CATEGORY = "START_DELETE_CATEGORY"
 export const DELETE_CATEGORY = "DELETE_CATEGORY"
 export const FAILED_DELETE_CATEGORY = "FAILED_DELETE_CATEGORY"
 export const START_DELETE_ACCOUNTS = "START_DELETE_ACCOUNTS"
 export const DELETE_ACCOUNTS = "DELETE_ACCOUNTS"
 export const FAILED_DELETE_ACCOUNTS = "FAILED_DELETE_ACCOUNTS"
 export const START_EDIT_ACCOUNT = "START_EDIT_ACCOUNT"
 export const EDIT_ACCOUNT = "EDIT_ACCOUNT"
 export const FAILED_EDIT_ACCOUNT = "FAILED_EDIT_ACCOUNT"
 export const START_GET_ACCOUNTS_EMPLOYEE = "START_GET_ACCOUNTS_EMPLOYEE"
 export const GET_ACCOUNTS_EMPLOYEE = "GET_ACCOUNTS_EMPLOYEE"
 export const FAILED_GET_ACCOUNTS_EMPLOYEE = "FAILED_GET_ACCOUNTS_EMPLOYEE"
 export const START_GET_ACCOUNTS_CATEGORY = "START_GET_ACCOUNTS_CATEGORY"
 export const GET_ACCOUNTS_CATEGORY = "GET_ACCOUNTS_CATEGORY"
 export const FAILED_GET_ACCOUNTS_CATEGORY = "FAILED_GET_ACCOUNTS_CATEGORY"
 export const START_EDIT_ACCOUNTS_FILE = "START_EDIT_ACCOUNTS_FILE"
 export const EDIT_ACCOUNTS_FILE = "EDIT_ACCOUNTS_FILE"
 export const FAILED_EDIT_ACCOUNTS_FILE = "FAILED_EDIT_ACCOUNTS_FILE"
 export const START_DELETE_ACCOUNT = "START_DELETE_ACCOUNT"
 export const DELETE_ACCOUNT = "DELETE_ACCOUNT"
 export const FAILED_DELETE_ACCOUNT = "FAILED_DELETE_ACCOUNT"
 export const START_DELETE_TWEETS = "START_DELETE_TWEETS"
 export const DELETE_TWEETS = "DELETE_TWEETS"
 export const FAILED_DELETE_TWEETS = "FAILED_DELETE_TWEETS"
 export const START_CREATE_TWEETS_ACCOUNTS = "START_CREATE_TWEETS_ACCOUNTS"
 export const CREATE_TWEETS_ACCOUNTS = "CREATE_TWEETS_ACCOUNTS"
 export const FAILED_CREATE_TWEETS_ACCOUNTS = "FAILED_CREATE_TWEETS_ACCOUNTS"
 export const START_CREATE_FOLLOW = "START_CREATE_FOLLOW"
 export const CREATE_FOLLOW = "CREATE_FOLLOW"
 export const FAILED_CREATE_FOLLOW = "FAILED_CREATE_FOLLOW"
 export const START_DELETE_FOLLOW = "START_DELETE_FOLLOW"
 export const DELETE_FOLLOW = "DELETE_FOLLOW"
 export const FAILED_DELETE_FOLLOW = "FAILED_DELETE_FOLLOW"
 export const START_CREATE_LIKE = "START_CREATE_LIKE"
 export const CREATE_LIKE = "CREATE_LIKE"
 export const FAILED_CREATE_LIKE = "FAILED_CREATE_LIKE"
 export const START_DELETE_LIKE = "START_DELETE_LIKE"
 export const DELETE_LIKE = "DELETE_LIKE"
 export const FAILED_DELETE_LIKE = "FAILED_DELETE_LIKE"
 export const START_DELETE_ONE_TWEET = "START_DELETE_ONE_TWEET"
 export const DELETE_ONE_TWEET = "DELETE_ONE_TWEET"
 export const FAILED_DELETE_ONE_TWEET = "FAILED_DELETE_ONE_TWEET"
 export const START_CREATE_TWEET = "START_CREATE_TWEET"
 export const CREATE_TWEET = "CREATE_TWEET"
 export const FAILED_CREATE_TWEET = "FAILED_CREATE_TWEET"
 export const START_CREATE_RETWEET = "START_CREATE_RETWEET"
 export const CREATE_RETWEET = "CREATE_RETWEET"
 export const FAILED_CREATE_RETWEET = "FAILED_CREATE_RETWEET"
 export const START_DELETE_RETWEET = "START_DELETE_RETWEET"
 export const DELETE_RETWEET = "DELETE_RETWEET"
 export const FAILED_DELETE_RETWEET = "FAILED_DELETE_RETWEET"
 export const START_CREATE_REPLY_ACCOUNTS = "START_CREATE_REPLY_ACCOUNTS"
 export const CREATE_REPLY_ACCOUNTS = "CREATE_REPLY_ACCOUNTS"
 export const FAILED_CREATE_REPLY_ACCOUNTS = "FAILED_CREATE_REPLY_ACCOUNTS"
 export const START_GET_DATA_INFO = "START_GET_DATA_INFO"
 export const GET_DATA_INFO = "GET_DATA_INFO"
 export const FAILED_GET_DATA_INFO = "FAILED_GET_DATA_INFO"
 export const START_DELETE_SOME_ACCOUNTS = "START_DELETE_SOME_ACCOUNTS"
 export const DELETE_SOME_ACCOUNTS = "DELETE_SOME_ACCOUNTS"
 export const FAILED_DELETE_SOME_ACCOUNTS = "FAILED_DELETE_SOME_ACCOUNTS"
 export const START_CHECK = "START_CHECK"
 export const CHECK = "CHECK"
 export const FAILED_CHECK = "FAILED_CHECK"
 export const START_UNLOCK = "START_UNLOCK"
 export const UNLOCK = "UNLOCK"
 export const FAILED_UNLOCK = "FAILED_UNLOCK"
 export const START_SHOW_TWEET = "START_SHOW_TWEET"
 export const SHOW_TWEET = "SHOW_TWEET"
 export const FAILED_SHOW_TWEET = "FAILED_SHOW_TWEET"
 export const START_DELETE_SOME_TASKS = "START_DELETE_SOME_TASKS"
 export const START_DELETE_SOME_DEPARTMENTS = "START_DELETE_SOME_DEPARTMENTS"
 export const DELETE_SOME_DEPARTMENTS = "DELETE_SOME_DEPARTMENTS"
 export const FAILED_DELETE_SOME_DEPARTMENTS = "FAILED_DELETE_SOME_DEPARTMENTS"
 export const START_DELETE_SOME_CATEGORIES = "START_DELETE_SOME_CATEGORIES"
 export const DELETE_SOME_CATEGORIES = "DELETE_SOME_CATEGORIES"
 export const FAILED_DELETE_SOME_CATEGORIES = "FAILED_DELETE_SOME_CATEGORIES"
 export const START_DELETE_SOME_CATEGORIES_ACCOUNTS = "START_DELETE_SOME_CATEGORIES_ACCOUNTS"
 export const DELETE_SOME_CATEGORIES_ACCOUNTS = "DELETE_SOME_CATEGORIES_ACCOUNTS"
 export const DELETE_SOME_CATEGORIES_INSTA_ACCOUNTS = "DELETE_SOME_CATEGORIES_INSTA_ACCOUNTS"
 export const FAILED_DELETE_SOME_CATEGORIES_ACCOUNTS = "FAILED_DELETE_SOME_CATEGORIES_ACCOUNTS"
 export const CREATE_INSTA_POSTS = "CREATE_INSTA_POSTS"
 export const FAILED_CREATE_INSTA_POSTS_ACCOUNTS = "FAILED_CREATE_INSTA_POSTS_ACCOUNTS"
 export const START_CREATE_INSTA_POSTS_ACCOUNTS = "START_CREATE_INSTA_POSTS_ACCOUNTS"
 export const STORE_MESSAGE = "STORE_MESSAGE"
 export const Message_Count = "Message_Count"
 export const Get_Notifications = "Get_Notifications"
 export const Get_Conversatons = "Get_Conversatons"
 export const Get_Conversaton = "Get_Conversaton"
 export const New_Message = "New_Message"
 export const New_Notification = "New_Notification"
 export const Add_Message_Conversaton = "Add_Message_Conversaton"
 export const Get_Tasks_Planner = "Get_Tasks_Planner"
 export const Get_Charts = "Get_Charts"
 export const Get_Cv = "Get_Cv"
 export const New_Cv = "New_Cv"
 export const Get_Unread_Messages = "Get_Unread_Messages"
 export const Set_read_Messages = "Set_read_Messages"
 export const Add_UnreadMessage = "Add_UnreadMessage"
 export const CREATE_TASK_Card = "CREATE_TASK_Card"
 export const Get_Tasks_Cards = "Get_Tasks_Cards"
 export const DELETE_SOME_EMPLOYEE = "DELETE_SOME_EMPLOYEE"
 export const FAILED_DELETE_SOME_EMPLOYEE = "FAILED_DELETE_SOME_EMPLOYEE"
 export const START_DELETE_SOME_EMPLOYEE = "START_DELETE_SOME_EMPLOYEE"
 export const GET_RATE = "GET_RATE"
 export const START_GET_RATE = "START_GET_RATE"
 export const FAILED_GET_RATE = "FAILED_GET_RATE"
 export const CREATE_EMPLOYEE_CHAT = "CREATE_EMPLOYEE_CHAT"
 export const Add_Conversation = "ADD_CONVERSATION";
 export const Task_Delivery = "Task_Delivery";
 export const GET_NEW_TASKS_EMPLOYEE = "GET_NEW_TASKS_EMPLOYEE";
 export const New_Chat = "New_Chat";
 export const RESET_CHAT = "RESET_CHAT";
 export const CREATE_TWEET_FOR_PUBLISHER = "CREATE_TWEET_FOR_PUBLISHER";
 export const PUBLISH_TWEET = "PUBLISH_TWEET";
 export const GET_TWEETS_NOT_PUBLISH = "GET_TWEETS_NOT_PUBLISH";
 export const GET_TWEETS_FOR_EMPLOYEE = "GET_TWEETS_FOR_EMPLOYEE";
 export const START_GET_TWEETS = "START_GET_TWEETS";
 export const FAILED_GET_TWEETS = "FAILED_GET_TWEETS";
 export const START_CREATE_TWEET_FOR_PUBLISHER = "START_CREATE_TWEET_FOR_PUBLISHER";
 export const FAILED_CREATE_TWEET_FOR_PUBLISHER = "FAILED_CREATE_TWEET_FOR_PUBLISHER";
 export const START_PUBLISH_TWEET = "START_PUBLISH_TWEET";
 export const FAILED_PUBLISH_TWEET = "FAILED_PUBLISH_TWEET";
 export const START_CHECK_ACCOUNT_TWEET = "START_CHECK_ACCOUNT_TWEET";
 export const FAILED_CHECK_ACCOUNT_TWEET = "FAILED_CHECK_ACCOUNT_TWEET";
 export const CHECK_ACCOUNT_TWEET = "CHECK_ACCOUNT_TWEET";
 export const START_GET_CATEGORIES_TWEETER_ACCOUNT = "START_GET_CATEGORIES_TWEETER_ACCOUNT";
 export const CREATE_CATEGORY_TWEETER_ACCOUNT = "CREATE_CATEGORY_TWEETER_ACCOUNT";
 export const EDIT_CATEGORY_TWEETER_ACCOUNT = "EDIT_CATEGORY_TWEETER_ACCOUNT";
 export const DELETE_CATEGORY_TWEETER_ACCOUNT = "DELETE_CATEGORY_TWEETER_ACCOUNT";
 export const START_DELETE_CATEGORY_TWEETER_ACCOUNT = "START_DELETE_CATEGORY_TWEETER_ACCOUNT";
 export const FAILED_DELETE_CATEGORY_TWEETER_ACCOUNT = "FAILED_DELETE_CATEGORY_TWEETER_ACCOUNT";
 export const FAILED_GET_CATEGORIES_TWEETER_ACCOUNT = "FAILED_GET_CATEGORIES_TWEETER_ACCOUNT";
 export const GET_CATEGORIES_TWEETER_ACCOUNT = "GET_CATEGORIES_TWEETER_ACCOUNT";
 export const START_GET_CATEGORIES_INSTA_ACCOUNT = "START_GET_CATEGORIES_INSTA_ACCOUNT";
 export const GET_CATEGORIES_INSTA_ACCOUNT = "GET_CATEGORIES_INSTA_ACCOUNT";
 export const FAILED_GET_CATEGORIES_INSTA_ACCOUNT = "FAILED_GET_CATEGORIES_INSTA_ACCOUNT";
 export const CREATE_CATEGORY_INSTA_ACCOUNT = "CREATE_CATEGORY_INSTA_ACCOUNT";
 export const START_CREATE_CATEGORY_INSTA_ACCOUNT = "START_CREATE_CATEGORY_INSTA_ACCOUNT"
 export const EDIT_CATEGORY_INSTA_ACCOUNT = "EDIT_CATEGORY_INSTA_ACCOUNT";
 export const DELETE_CATEGORY_INSTA_ACCOUNT = "DELETE_CATEGORY_INSTA_ACCOUNT";
 export const START_DELETE_CATEGORY_INSTA_ACCOUNT = "START_DELETE_CATEGORY_INSTA_ACCOUNT";
 export const FAILED_DELETE_CATEGORY_INSTA_ACCOUNT = "FAILED_DELETE_CATEGORY_INSTA_ACCOUNT";
 export const FAILED_CREATE_CATEGORY_INSTA_ACCOUNT = "FAILED_CREATE_CATEGORY_INSTA_ACCOUNT"
 export const START_EDIT_CATEGORY_INSTA_ACCOUNT = "START_EDIT_CATEGORY_INSTA_ACCOUNT"
 export const FAILED_EDIT_CATEGORY_INSTA_ACCOUNT = "FAILED_EDIT_CATEGORY_INSTA_ACCOUNT"
 export const START_GET_INSTA_ACCOUNTS = "START_GET_INSTA_ACCOUNTS"
 export const GET_INSTA_ACCOUNTS = "GET_INSTA_ACCOUNTS"
 export const FAILED_GET_INSTA_ACCOUNTS = "FAILED_GET_INSTA_ACCOUNTS"
 export const FAILED_EDIT_INSTA_ACCOUNT = "FAILED_EDIT_INSTA_ACCOUNT"
 export const GET_INSTA_ACCOUNTS_CATEGORY = "GET_INSTA_ACCOUNTS_CATEGORY"
 export const START_EDIT_INSTA_ACCOUNT = "START_EDIT_INSTA_ACCOUNT"
 export const EDIT_INSTA_ACCOUNT = "EDIT_INSTA_ACCOUNT"
 export const START_CREATE_INSTA_ACCOUNT_FILE = "START_CREATE_INSTA_ACCOUNT_FILE"
 export const CREATE_INSTA_ACCOUNT_FILE = "CREATE_INSTA_ACCOUNT_FILE"
 export const FAILED_CREATE_INSTA_ACCOUNT_FILE = "FAILED_CREATE_INSTA_ACCOUNT_FILE"
 export const DELETE_SOME_INSTA_ACCOUNTS = "DELETE_SOME_INSTA_ACCOUNTS"
 export const START_DELETE_FILE_CATEGORY = "START_DELETE_FILE_CATEGORY"
 export const DELETE_FILE_CATEGORY = "DELETE_FILE_CATEGORY"
 export const FAILED_DELETE_FILE_CATEGORY = "FAILED_DELETE_FILE_CATEGORY"


